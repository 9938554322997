<template>
  <div class="votes d-flex flex-column justify-content-between align-items-stretch" :class="vote.user ? 'votes_disabled' : ''">
    <div class="votes__image">
      <router-link :to="{name: 'VotesItem', params: {id: vote.id}}" :is="vote.user ? 'span' : 'router-link'">
        <img :src="image ? image: vote.image" :alt="vote.name">
        
      </router-link>
    </div>
    <div class="votes__link pt-4 text-end" v-if="vote.id">
      <router-link :to="{name: 'VotesItem', params: {id: vote.id}}" :is="vote.user ? 'span' : 'router-link'">
        {{text ? text : vote.user ? 'Опрос пройден' : 'Пройти опрос' }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoteItem",
  props: ['vote', 'image', 'text']
}
</script>

<style scoped lang="scss">
.votes {
  height: 100%;
  padding: rem(25) rem(40);
  position: relative;
  margin-bottom: rem(30);
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  @include media(992) {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  &_disabled {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(grey, .4);
    }
  }
  &__image {
    text-align: center;
    a {
      text-decoration: none;
    }
    img {
      max-width: 100%;
      vertical-align: top;
      @include media(768) {
        max-width: 250px;
        margin:  0 auto;
      }
    }

  }
  &__link {
    a, span {
      text-decoration: none;
      color: #000;
      font-size: rem(12);
      svg {
        margin-left: rem(10);
        max-width: 30px;
        height: auto;
      }
    }
  }
}
</style>