<template>
  <section class="section section_votes">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 class="title title_dark title_second title_svg">Опросы</h2>
        </div>
        <div class="col-12 col-lg-9">
          <div class="votes__descs pb-5">
            Выберите опрос от стратегического партнера и пройдите его.
          </div>
        </div>
        <div class="d-none d-lg-block col-3">
            <rasp-slider></rasp-slider>
        </div>
        <div class="col-12 col-lg-9">

          <div class="row pt-4 d-flex align-items-stretch">

            <div class="col-12 col-sm-6 col-md-4 mb-3" v-for="vote in votes" :key="vote.id">
              <vote-item :vote="vote"></vote-item>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import VoteItem from "../components/components/VoteItem";
import RaspSlider from "@/components/components/RaspSlider.vue";
export default {
  name: "Votes",
  components: {VoteItem, RaspSlider},
  data() {
    return {
      votes: []
    }
  },
  methods: {
    loadVotes() {
      this.$axios.get('votes')
        .then(resp => {
          this.votes = resp.data
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mounted() {
    this.loadVotes()
  }
}
</script>

<style scoped lang="scss">
  .section_votes {
    padding: rem(30) 0;
  }
  .votes {
    height: 100%;
    padding: rem(20);
    border-radius: 5px;
    position: relative;
    border: 1px solid rgba(#000 ,.3);
    margin-bottom: rem(30);
    &_disabled {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(grey, .4);
      }
    }
    &__image {
      max-width: 100%;
      vertical-align: top;
    }
    &__link {
      a {
        text-decoration: none;
        color: #000;
        font-size: rem(14);
        svg {
          margin-left: rem(10);
          max-width: 30px;
          height: auto;
        }
      }
    }
    &__descs {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 21px;
      /* identical to box height */


      /* Dark blue */

      color: #282938;
    }
  }
</style>